import { Trans, useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import { Layout, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import useWidth from "components/hooks/useWidth";
import {
  selectInstallerDetails,
  selectUserDetails,
} from "redux/user/selectors";
import { useAppState } from "redux/store";
import "../templates/DashBoard.css";
import {
  kanban,
  maps,
  projectListing,
  data,
  appLogo,
  ProductActiveIcon,
  constantIcon,
  constantActiveIcon,
} from "assets";

const { Sider } = Layout;

const SidebarItems = [
  {
    id: 1,
    text: "projectListing",
    icon: projectListing,
    link: "/listing",
  },
  {
    id: 2,
    text: "mapListing",
    icon: maps,
    link: "/mapview",
  },
  {
    id: 3,
    text: "statusView",
    icon: kanban,
    link: "/overview",
  },
];

const SidebarAdminItems = [
  {
    id: 1,
    text: "projectListing",
    icon: projectListing,
    link: "/listing",
  },
  {
    id: 2,
    text: "mapListing",
    icon: maps,
    link: "/mapview",
  },
  {
    id: 3,
    text: "statusView",
    icon: kanban,
    link: "/overview",
  },
  {
    id: 4,
    text: "solarhubView",
    icon: data,
    link: "/data",
  },
];

const SidebarCityAdminItems = [
  {
    id: 1,
    text: "projectListing",
    icon: projectListing,
    link: "/listing",
  },
  {
    id: 2,
    text: "mapListing",
    icon: maps,
    link: "/mapview",
  },
  {
    id: 4,
    text: "solarhubView",
    icon: data,
    link: "/data",
  },
];

interface LeftSidebarProps {
  state: boolean;
  toggle: any;
  mobile_state: boolean;
  setmobile_state: any;
  switchview: any;
  setswitchview: any;
}

const LeftSidebar = ({
  state,
  toggle,
  mobile_state,
  setmobile_state,
  switchview,
  setswitchview,
}: LeftSidebarProps) => {
  const history = useHistory();
  const { width } = useWidth();
  const currentUser = useAppState(selectUserDetails);
  const { t } = useTranslation();
  const userDetails = useAppState(selectUserDetails);
  const installerDetails = useAppState(selectInstallerDetails);

  useEffect(() => {
    const all_items: any = document.getElementsByClassName("ant-menu-item");
    Object.entries(all_items).map(([key, val]: any, ind: any) => {
      if (switchview < 5) {
        if (switchview == ind + 1) {
          val.classList.add("ant-menu-item-selected");
        } else {
          val.classList.remove("ant-menu-item-selected");
        }
      } else {
        if (ind < 5) {
          val.classList.remove("ant-menu-item-selected");
        }
      }
    });
  }, [switchview]);

  return (
    <Fragment>
      <Sider
        trigger={null}
        collapsible
        collapsed={width < 787 ? false : state}
        width={240}
        className={"md:top-0 md:left-0 md:z-10 md:absolute"}
        hidden={width < 787 ? !mobile_state : false}
      >
        <Menu
          style={{
            background:
              "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
            height: "100vh",
            paddingTop: 10,
          }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[`1`]}
        >
          <div
            className="flex justify-start items-center gap-4 md:hidden cursor-pointer"
            onClick={() => history.push("/listing")}
            style={{ minHeight: 56, height: 56 }}
          >
            <Fragment>
              <div
                className={
                  state ? "ml-5 w-10 h-10 relative" : "ml-4 w-10 h-10 relative"
                }
              >
                <img src={appLogo} className="h-10 w-10" alt="" />
              </div>
              <div
                className={
                  state
                    ? "hidden font-semibold text-lg text-white"
                    : "font-semibold text-lg text-white"
                }
                style={{ minHeight: 56, height: 56 }}
              >
                <span>
                  <Trans i18nKey="sidebarTitle" components={{ br: <br /> }} />
                </span>
              </div>
            </Fragment>
          </div>
          <div
            className="flex justify-start gap-4 mb-4 mt-2 items-center"
            style={{ minHeight: 40, height: 40 }}
          >
            <Fragment>
              <div
                className={
                  state ? "ml-5 w-10 h-10 relative" : "ml-4 w-10 h-10 relative"
                }
              >
                <Avatar
                  style={{
                    verticalAlign: "middle",
                    background: "slategray",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                  size="large"
                  className="h-full w-full"
                >
                  <Link to={"/profile"} className="align-start">
                    {currentUser?.email?.substr(0, 1)?.toUpperCase()}
                  </Link>
                </Avatar>
              </div>
              <div
                className={
                  width < 787
                    ? "text-lg align-middle text-white md:flex md:flex-col"
                    : "hidden"
                }
              >
                <span>Project Panel</span>{" "}
                <span> {currentUser?.firstName}</span>
              </div>
              <div
                className={
                  state
                    ? "hidden md:hidden"
                    : "text-lg align-middle text-white animate md:hidden"
                }
              >
                {currentUser?.firstName}
              </div>
            </Fragment>
          </div>
          {!state ? (
            <div
              className="absolute bottom-7 left-6 flex gap-4 justify-center items-center text-white text-base cursor-pointer"
              onClick={toggle}
            >
              <div>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.895431 0 0 0.895431 0 2V17C0 18.1046 0.895431 19 2 19H17C18.1046 19 19 18.1046 19 17V2C19 0.895431 18.1046 0 17 0H2ZM6.42857 2.71429C5.87629 2.71429 5.42857 3.162 5.42857 3.71428V15.2857C5.42857 15.838 5.87629 16.2857 6.42857 16.2857H7.14286C7.69514 16.2857 8.14286 15.838 8.14286 15.2857V3.71429C8.14286 3.162 7.69514 2.71429 7.14286 2.71429H6.42857Z"
                    fill="#FFD75D"
                  />
                </svg>
              </div>
              <div>{t("hideSidebar")}</div>
            </div>
          ) : null}

          {(userDetails?.role === "ADMIN"
            ? SidebarAdminItems
            : userDetails?.role === "CITY_ADMIN"
            ? SidebarCityAdminItems
            : SidebarItems
          ).map((item: any, ind: number) => {
            return (
              <Menu.Item
                disabled={item?.disabled}
                key={item.id}
                icon={<img src={item.icon} alt="" />}
                className="p-0 pl-7 menu-item"
                onClick={() => {
                  if (item.id > 3) {
                    setswitchview(4);
                  }
                  setmobile_state(false);
                }}
              >
                <Link to={item.link} className="align-start">
                  {t(item.text)}
                </Link>
              </Menu.Item>
            );
          })}

          {installerDetails?._id && userDetails?.role !== "CITY_ADMIN" && (
            <>
              <Menu.Item
                key={5}
                icon={<img src={ProductActiveIcon} alt="" />}
                className="p-0 pl-7 menu-item"
                onClick={() => {
                  setswitchview(5);
                }}
              >
                <Link to={"/product"} className="align-start">
                  {t("Product")}
                </Link>
              </Menu.Item>
              <Menu.Item
                key={6}
                icon={<img src={constantActiveIcon} alt="" />}
                className="p-0 pl-7 menu-item"
                onClick={() => {
                  setswitchview(6);
                }}
              >
                <Link to={"/profile"} className="align-start">
                  {t("Settings")}
                </Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
    </Fragment>
  );
};

export default LeftSidebar;
